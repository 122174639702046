$(document).ready(function () {
    /* $(".multiple-address").on("click", function (event) {
        var html = '<div class="row module">' + '<h2>Telephely</h2>' + '<div class="col-2 form-floating shipping-data prep">' +
            '<input type="text" class="form-control" placeholder="Irányítószám" aria-label="postal_zip[]" aria-describedby="basic-addon1">' +
            '<label class="form-control-placeholder" for="postal_zip[]">Irányítószám</label>' +
            '</div>' +
            '<div class="col-5 form-floating shipping-data">' +
            '<input type="text" class="form-control" placeholder="Település" aria-label="city[]" aria-describedby="basic-addon1">' +
            '<label class="form-control-placeholder" for="city[]">Település</label>' +
            '</div>' +
            '<div class="col-5 form-floating shipping-data">' +
            '<input type="text" class="form-control" placeholder="Közterület, Házszám" aria-label="address[]" aria-describedby="basic-addon1">' +
            '<label class="form-control-placeholder" for="address[]">Közterület, Házszám</label>' +
            '</div>' +
            '<div class="col-4 form-floating shipping-data">' +
            '<input type="text" class="form-control" placeholder="Kapcsolattartó neve" aria-label="contact_name[]" aria-describedby="basic-addon1">' +
            '<label class="form-control-placeholder" for="contact_name[]">Kapcsolattartó neve</label>' +
            '</div>' +
            '<div class="col-4 form-floating shipping-data">' +
            '<input type="text" class="form-control" placeholder="Telefonszám" aria-label="contact_phone[]" aria-describedby="basic-addon1">' +
            '<label class="form-control-placeholder" for="contact_phone[]">Telefonszám</label>' +
            '</div>' +
            '<div class="col-4 form-floating shipping-data">' +
            '<input type="text" class="form-control" placeholder="E-mail cím" aria-label="contact_email[]" aria-describedby="basic-addon1">' +
            '<label class="form-control-placeholder" for="contact_email[]">E-mail cím</label>' +
            '</div>' + '<div/>';


        $("#prep-point").append(html);
    }); */
});
